import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, NgZone, ErrorHandler } from '@angular/core'

import { Observable, combineLatest, BehaviorSubject, of, firstValueFrom } from 'rxjs'
import { map, startWith, switchMap, tap } from 'rxjs/operators'
import { KlikattuKirjaus, RaporttienHakuvaihtoehdot } from '../raportit.component'
import { Kirjanpitotili, RaporttiRequest, RaporttiAlvLaskelmaDataResponse, RaporttiAlvLaskelmaData, RaporttiAlvLaskelmaAlvData, RaporttiAlvLaskelmaRow } from 'app/_jaettu-lemonator/model/kirjanpito'
import { RaporttiType } from 'app/_jaettu/model/reports-shared'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { Asiakas, AsiakkaanMaksutapa } from 'app/_jaettu-lemonator/model/asiakas'
import { MaaService } from 'app/_jaettu-angular/service/maa.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { CodeCheckService } from 'app/_shared-core/service/code-check.service'
import { AlvLemonatorService } from 'app/_angular/service/alv-lemonator.service'

import { DateService } from 'app/_shared-core/service/date.service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { Timestamp, TuettuKieli } from 'app/_shared-core/model/common'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { TilikarttaJaettuService } from 'app/_jaettu-lemonator/service/tilikartta-jaettu.service'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'

type AlvLaskelmaOstoVaiMyyntiTyyppi = 'myynti' | 'osto'

@Component({
  selector: '[app-kirjanpito-alv-laskelma]',
  templateUrl: './alv-laskelma.component.html',
  styleUrls: ['./alv-laskelma.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpitoRaportitAlvLaskelmaComponent implements OnInit {

  @Input() hakuvaihtoehdotObservable: Observable<RaporttienHakuvaihtoehdot>
  @Input() tilitMapObservable: Observable<Map<string, Kirjanpitotili>>
  @Input() paivitaArvotHiljaisestiSubject: BehaviorSubject<number>

  @Output() kirjaustaKlikattiin: EventEmitter<KlikattuKirjaus> = new EventEmitter()

  loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(true)
  dataObservable: Observable<RaporttiAlvLaskelmaData>
  lastSucessfullyUpdated: Timestamp

  private _expandedAccountsBehaviorSubject: BehaviorSubject<Set<string>> = new BehaviorSubject(new Set())

  constructor(
    private _errorHandler: ErrorHandler,
    private _ngZone: NgZone,
    private _firebase: FirebaseLemonator,
    private _asiakasService: AsiakasService,
    private _maaService: MaaService,
    private _currencyService: CurrencyService,
    private _codeCheckService: CodeCheckService,
    private _alvLemonatorService: AlvLemonatorService,
    private _dateService: DateService,
    private _timestampService: TimestampService,
    private _tilikarttaJaettuService: TilikarttaJaettuService,
    private _fileSaverService: FileSaverService
  ) { }

  ngOnInit() {

    const laskelmanRaakadataObservable = combineLatest([
      this._asiakasService.nykyinenAsiakasAvainObservable,
      this.hakuvaihtoehdotObservable.pipe(
        tap(() => {
          this._setLoadingTrue()
        })
      ),
      this.paivitaArvotHiljaisestiSubject
    ]).pipe(
      switchMap(([asiakas, hakuvaihtoehdot, paivita]) => {

        if (!hakuvaihtoehdot?.alkaa || !hakuvaihtoehdot?.loppuu) {
          return of<RaporttiAlvLaskelmaData>(null)
        }

        const haku: RaporttiRequest = {
          a: asiakas.avain,
          k: 'fi',
          w: RaporttiType.ALV_LASKELMA,
          s: hakuvaihtoehdot.alkaa,
          e: hakuvaihtoehdot.loppuu
        }
        // if (hakuvaihtoehdot.tilista) { haku.b = hakuvaihtoehdot.tilista }
        // if (hakuvaihtoehdot.tiliin) { haku.c = hakuvaihtoehdot.tiliin }
        if (hakuvaihtoehdot.vapaasanahaku?.trim()) { haku.t = hakuvaihtoehdot.vapaasanahaku.trim() }
        if (hakuvaihtoehdot.projekti) { haku.p = hakuvaihtoehdot.projekti }

        if (this._expandedAccountsBehaviorSubject.value.size > 0) {
          haku.f = Array.from(this._expandedAccountsBehaviorSubject.value)
        }

        return this._firebase.functionsCall<RaporttiRequest, RaporttiAlvLaskelmaDataResponse>('kirjanpitoRaportitData', haku).then(res => {
          if (res.e) {
            throw new Error(res.e)
          }
          this.lastSucessfullyUpdated = this._timestampService.now()
          this._setLoadingFalse()
          return res.data
        }).catch(err => {
          console.error('Failed to fetch report data', err)
          this._errorHandler.handleError(err)
        })

      }),
      startWith<RaporttiAlvLaskelmaData>({ myynnit: [], ostot: [] })
    )

    const maksutavatMapObservable: Observable<Map<string, AsiakkaanMaksutapa>> = this._asiakasService.nykyisenAsiakkaanKaikkiMaksutavatObservable.pipe(
      map(maksutavat => {
        const paymentMethodsMap = new Map<string, AsiakkaanMaksutapa>()
        for (const m of maksutavat) {
          paymentMethodsMap.set(m.tunniste + '', m)
        }
        return paymentMethodsMap
      })
    )

    const dataJaNimet = combineLatest([
      this.tilitMapObservable,
      laskelmanRaakadataObservable,
      maksutavatMapObservable
    ]).pipe(
      switchMap(async ([tiliMap, laskelmanRaakadata, maksutavatMap]) => {
        if (!tiliMap || !laskelmanRaakadata) {
          return null
        }
        await this._alvLaskelmaAddAccountNames(tiliMap, laskelmanRaakadata, maksutavatMap, 'fi')
        return laskelmanRaakadata
      })
    )

    this.dataObservable = combineLatest([dataJaNimet, this._expandedAccountsBehaviorSubject]).pipe(
      map(([data, rivienExpand]) => {
        if (data?.myynnit) {
          for (const r of data.myynnit) {
            if (!r.rivit) { continue }
            for (const rr of r.rivit) {
              console.log('Check', this._annaAvain(r.alv, rr.a, 'myynti'))
              if (rivienExpand.has(this._annaAvain(r.alv, rr.a, 'myynti'))) {
                console.log('SET')
                rr.e = 1
              } else {
                delete rr.e
              }
            }
          }
        }
        if (data?.ostot) {
          for (const r of data.ostot) {
            if (!r.rivit) { continue }
            for (const rr of r.rivit) {
              if (rivienExpand.has(this._annaAvain(r.alv, rr.a, 'osto'))) {
                rr.e = 1
              } else {
                delete rr.e
              }
            }
          }
        }
        return data
      })
    )

  }

  private _setLoadingTrue() {
    setTimeout(() => {
      this._ngZone.run(() => {
        this.loadingSubject.next(true)
      })
    }, 0)
  }

  private _setLoadingFalse() {
    setTimeout(() => {
      this._ngZone.run(() => {
        this.loadingSubject.next(false)
      })
    }, 0)
  }

  private async _alvLaskelmaAddAccountNames(tiliMap: Map<string, Kirjanpitotili>, data: RaporttiAlvLaskelmaData, maksutavatMap: Map<string, AsiakkaanMaksutapa>, kieli: TuettuKieli) {
    if (!tiliMap || !data) {
      return
    }

    data.myyntiSums = {
      b: 0,
      s: 0,
      t: 0
    }

    if (data.myynnit) {
      await this._addAlvLaskelmaNames(tiliMap, data.myynnit, data.myyntiSums, maksutavatMap, kieli)
    }

    data.ostoSums = {
      b: 0,
      s: 0,
      t: 0
    }

    if (data.ostot) {
      await this._addAlvLaskelmaNames(tiliMap, data.ostot, data.ostoSums, maksutavatMap, kieli)
    }
  }

  private async _addAlvLaskelmaNames(tiliMap: Map<string, Kirjanpitotili>, datat: RaporttiAlvLaskelmaAlvData[], sums: { b: number, t: number, s: number }, maksutavatMap: Map<string, AsiakkaanMaksutapa>, kieli: TuettuKieli) {
    for (const row of datat) {
      const maaritys = await this._alvLemonatorService.annaMaaritys(row.alv)
      row.n = maaritys?.nimi ?? row.alv
      row.b = 0
      row.s = 0
      row.t = 0
      if (row.rivit) {
        for (const r of row.rivit) {
          r.n = r.a + ' ' + this._tilikarttaJaettuService.annaKirjanpitotilinNimi(tiliMap.get(r.a), kieli)
          sums.b += r.b
          sums.s += r.s
          sums.t += r.t
          row.b += r.b
          row.s += r.s
          row.t += r.t
          if (r.d1) {
            for (const d of r.d1) {
              d.ma = maksutavatMap.get(d.m)?.nimi || ''
            }
          }
          if (r.d2) {
            for (const d of r.d2) {
              d.ma = maksutavatMap.get(d.m)?.nimi || ''
            }
          }
        }
      }
    }
  }

  private _annaAvain(alvTunniste: string, tilinumero: string, type: AlvLaskelmaOstoVaiMyyntiTyyppi): string {
    return type + '_' + alvTunniste + '_' + tilinumero
  }

  handleClick(event: MouseEvent, data: RaporttiAlvLaskelmaAlvData[], type: AlvLaskelmaOstoVaiMyyntiTyyppi) {
    const element = event.target as HTMLElement
    console.log('Handle click', element)
    if (element.dataset.n) {
      this.kirjaustaKlikattiin.emit({ kirjausnumero: element.dataset.n })
    } else if (element?.classList?.contains('n')) {
      const tilinumero = element.parentElement.dataset.tnro
      const vat = element.parentElement.dataset.vat
      const avain = this._annaAvain(vat, tilinumero, type)
      const set = this._expandedAccountsBehaviorSubject.value
      if (set.has(avain)) {
        set.delete(avain)
      } else {
        set.add(avain)
        this.paivitaArvotHiljaisestiSubject.next(this.paivitaArvotHiljaisestiSubject.value + 1)
      }
      this._expandedAccountsBehaviorSubject.next(set)
      console.log('Added', avain)
      event.preventDefault()
      event.stopPropagation()
    }

    // else if (data?.length) {
    //   const tr = this._findTr(element)
    //   if (tr?.dataset.vat !== undefined && tr?.dataset.tnro !== undefined) {
    //     const closestBody = tr.parentElement as HTMLTableSectionElement
    //     if (closestBody?.dataset?.n) {
    //       // console.log('secondTd found', closestBody)
    //       const tilinumero = closestBody.dataset.n
    //       const tilinItem = data?.rivit.find(a => a.a === tilinumero)
    //       if (tilinItem?.d) {
    //         // console.log('item found', tilinItem)
    //         const d = tilinItem.d[Number(tr?.dataset.i)]
    //         if (d.l) {
    //           delete d.l
    //         } else {
    //           d.l = true
    //         }
    //       }
    //     }
    //   }
    // }
  }

  private _findTr(elem: HTMLElement): HTMLTableRowElement {
    if (elem?.tagName === 'TR') {
      return elem as HTMLTableRowElement
    } else if (elem?.parentElement?.tagName === 'TR') {
      return elem.parentElement as HTMLTableRowElement
    } else if (elem?.parentElement?.parentElement?.tagName === 'TR') {
      return elem.parentElement.parentElement as HTMLTableRowElement
    }
    return null
  }

  trackAccountRowByAccountNumberFn(index: number, item: RaporttiAlvLaskelmaAlvData) {
    return index
  }

  // async createCsvExportFile() {
  //   const [asiakas, hakuvaihtoehdot] = await Promise.all([
  //     firstValueFrom(this._asiakasService.nykyinenAsiakasObservable),
  //     firstValueFrom(this.hakuvaihtoehdotObservable)
  //   ])
  //   const rows = await this._makeExportFileRows(this._laskelmanRaakadata, asiakas)
  //   let csv = 'Rivinro;Tunniste;Ilmoitustyyppi;Kulutusvaltio;Myyntityyppi;Verotyyppi;Veroprosentti;Peruste;Maara;Myyntipaikka;Korjauskulutusvaltio;Korjausverokausi;Korjausmaara\r\n'
  //   for (const row of rows) {
  //     csv += `${row.a};${this._s(row.b)};${this._s(row.c)};${this._s(row.d)};${this._s(row.e)};${this._s(row.f)};${this._n(row.g)};${this._n(row.h)};${this._n(row.i)};${this._s(row.j)};${this._s(row.k)};${this._s(row.l)};${this._s(row.m)}\r\n`
  //   }
  //   const alkaa = this._dateService.muotoilePaikallinenPaiva(this._dateService.numberToLocalDate(hakuvaihtoehdot.alkaa), 'fi')
  //   const loppuu = this._dateService.muotoilePaikallinenPaiva(this._dateService.numberToLocalDate(hakuvaihtoehdot.loppuu), 'fi')
  //   const filename = asiakas.ytunnus + ' OSS ' + alkaa + '-' + loppuu + ' OmaVero export.csv'
  //   this._fileSaverService.saveStringAs(csv, filename, 'csv')
  // }

  // private _n(v: number): string {
  //   if (v === null || v === undefined) {
  //     return ''
  //   }
  //   return this._currencyService.formatoiDesimaaliSailytaNollat(v, 2, 'fi')
  // }

  // private _s(v: string): string {
  //   if (!v) {
  //     return ''
  //   }
  //   return v
  // }

  // private async _makeExportFileRows(data: RaporttiOssData, asiakas: Asiakas): Promise<GovenrmentOssExportRow[]> {
  //   const ret: GovenrmentOssExportRow[] = []

  //   let myynninTyyppi: '1' | '2' = null
  //   for (const r of data.r) {
  //     if (r.l === 1) {
  //       if (r.a === '3361') {
  //         myynninTyyppi = '1'
  //       } else if (r.a === '3362') {
  //         myynninTyyppi = '2'
  //       } else {
  //         throw new Error('Unknown tili: ' + r.a)
  //       }
  //     } else if (r.l === 2) {
  //       if (!myynninTyyppi) {
  //         throw new Error('Ei myynnin tyyppiä.')
  //       }
  //       const maaritys = await this._alvLemonatorService.annaMaaritys(r.v)
  //       if (!maaritys) {
  //         throw new Error('Unknown määritys: ' + r.v)
  //       }

  //       const alpha2Key = this._maaService.getAlpha2Code(r.m)
  //       const alpha2KeyWithOverride = alpha2Key === 'GR' ? 'EL' : alpha2Key
  //       const row: GovenrmentOssExportRow = {
  //         a: ret.length + 1,
  //         b: this._codeCheckService.annaVatTunnus(asiakas.ytunnus),
  //         c: '1',
  //         d: alpha2KeyWithOverride,
  //         e: myynninTyyppi,
  //         f: maaritys.oletus ? '1' : '2',
  //         g: r.p,
  //         h: r.w,
  //         i: r.w * (r.p / 100),
  //         j: null,
  //         k: null,
  //         l: null,
  //         m: null
  //       }
  //       console.log(row)
  //       ret.push(row)

  //     }
  //   }
  //   return ret
  // }

}
