import { LocalDate, Timestamp, KuukausiAikavali, LocalMonth, LocalDateTime, NumberDate, NumberMonth, NumberTimestamp, TuettuKieli, ZonedDateTime } from '../model/common'

import { formatRFC7231, parseISO, differenceInMinutes, differenceInCalendarDays, differenceInCalendarMonths, differenceInQuarters, addMinutes, addDays, addMonths, format, getDayOfYear, startOfDay, endOfDay, startOfMonth, endOfMonth, max, min, isSaturday, isSunday, isEqual, compareAsc, compareDesc, getDaysInMonth, startOfQuarter, endOfQuarter } from 'date-fns'

export type ComparisonOperators = '<' | '>' | '==' | '!=' | '<=' | '>='

export class DateService {

  readonly KUUKAUSIEN_NIMET = {
    'fi': {
      'long': ['Tammikuu', 'Helmikuu', 'Maaliskuu', 'Huhtikuu', 'Toukokuu', 'Kesäkuu', 'Heinäkuu', 'Elokuu', 'Syyskuu', 'Lokakuu', 'Marraskuu', 'Joulukuu'],
      'short': ['Tammi', 'Helmi', 'Maalis', 'Huhti', 'Touko', 'Kesä', 'Heinä', 'Elo', 'Syys', 'Loka', 'Marras', 'Joulu'],
      'narrow': ['Tam', 'Hel', 'Maa', 'Huh', 'Tou', 'Kes', 'Hei', 'Elo', 'Syy', 'Lok', 'Mar', 'Jou']
    },
    'en': {
      'long': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      'short': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      'narrow': ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
    }
  }

  readonly PAIVIEN_NIMET = {
    'fi': {
      'long': ['Sunnuntai', 'Maanantai', 'Tiistai', 'Keskiviikko', 'Torstai', 'Perjantai', 'Lauantai'],
      'short': ['Su', 'Ma', 'Ti', 'Ke', 'To', 'Pe', 'La'],
      'narrow': ['Su', 'Ma', 'Ti', 'Ke', 'To', 'Pe', 'La']
    },
    'en': {
      'long': ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      'short': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      'narrow': ['S', 'M', 'T', 'W', 'T', 'F', 'S']
    }
  }

  /**
  * Note! Null is considered max value
  */
  max(dates: Date[]): Date | null {
    if (dates && dates.length) {
      return max(dates)
    }
    return null
  }

  min(dates: Date[]): Date | null {
    if (dates && dates.length) {
      return min(dates)
    }
    return null
  }

  isValidDate(date: Date): boolean {
    if (isNaN(date.getTime())) {
      throw Error('Date ' + date + ' is invalid.')
    }
    return true
  }

  puraAikavali(aikavali: KuukausiAikavali): LocalMonth[] {
    if (!aikavali) {
      return []
    }
    const paluuarvo: LocalMonth[] = []
    const muuttuva: LocalMonth = { year: aikavali.start.year, month: aikavali.start.month }
    while (this.onEnnenTaiSamaanAikaan(muuttuva, aikavali.end)) {
      paluuarvo.push({ year: muuttuva.year, month: muuttuva.month })
      muuttuva.month++
      if (muuttuva.month > 12) {
        muuttuva.month = 1
        muuttuva.year++
      }
    }
    return paluuarvo
  }

  // private onEnnen(aikaisempi: VuosiKk, myohempi: VuosiKk): boolean {
  //   if (aikaisempi.vuosi < myohempi.vuosi) {
  //     return true
  //   }
  //   if (aikaisempi.vuosi === myohempi.vuosi && aikaisempi.kuukausi < myohempi.kuukausi) {
  //     return true
  //   }
  //   return false
  // }

  laskeArkipaivatPaivienValilla(aikaisempi: Date, myohempi: Date): number {
    const paiviaValissa = differenceInCalendarDays(myohempi, aikaisempi)
    const alkaa = paiviaValissa >= 0 ? aikaisempi : myohempi
    const loppuu = paiviaValissa >= 0 ? myohempi : aikaisempi
    let paivia = 0
    for (const d = new Date(alkaa); d <= loppuu; d.setDate(d.getDate() + 1)) {
      // console.log('Tarkistetaan ' + d.getDay())
      if (0 < d.getDay() && d.getDay() < 6) {
        paivia++
      }
    }
    return paiviaValissa >= 0 ? paivia : 0 - paivia
  }
  annaEdellinenPerjantaiJosOnViikonloppu(aika: Date): Date {
    if (!aika) {
      return null
    }
    if (isSunday(aika)) {
      return this.lisaaPaivia(aika, -2)
    }
    if (isSaturday(aika)) {
      return this.lisaaPaivia(aika, -1)
    }
    return aika
  }
  annaSeuraavaMaanantaiJosOnViikonloppu(aika: Date): Date {
    if (!aika) {
      return null
    }
    if (isSunday(aika)) {
      return this.lisaaPaivia(aika, 1)
    }
    if (isSaturday(aika)) {
      return this.lisaaPaivia(aika, 2)
    }
    return aika
  }

  onEnnenTaiSamaanAikaan(aikaisempi: LocalMonth, myohempi: LocalMonth): boolean {
    if (aikaisempi.year < myohempi.year) {
      return true
    }
    if (aikaisempi.year === myohempi.year && aikaisempi.month <= myohempi.month) {
      return true
    }
    return false
  }

  muotoileRfc7231(date: Date): string {
    if (!date) {
      return null
    }
    return formatRFC7231(date)
  }

  muotoileRfc7231Timestamp(timestamp: Timestamp): string {
    if (!timestamp) {
      return null
    }
    return this.muotoileRfc7231(this.timestampToDate(timestamp))
  }

  annaPaikallinenPvm(pvm: Timestamp, lokaali?: LocalDate): LocalDate {
    if (lokaali) {
      return lokaali
    }
    return this.timestampToLocalDate(pvm)
  }

  timestampToLocalDate(timestamp: Timestamp): LocalDate | null {
    if (timestamp) {
      return this.dateToLocalDate(timestamp.toDate())
    }
    return null
  }

  timestampToDate(timestamp: Timestamp): Date | null {
    if (timestamp) {
      return timestamp.toDate()
    }
    return null
  }
  currentNumberDate(): NumberDate {
    return this.dateToNumber(new Date())
  }
  currentLocalDate(): LocalDate {
    return this.dateToLocalDate(new Date())
  }
  currentLocalMonth(): LocalMonth {
    return this.dateToLocalMonth(new Date())
  }
  localDate(
    year: number,
    month: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
    day: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31
  ): LocalDate {
    return this.dateToLocalDate(new Date(year, month - 1, day))
  }

  localDateToDate(lokaali: LocalDate): Date | null {
    if (lokaali) {
      return new Date(lokaali.year, lokaali.month - 1, lokaali.day)
    }
    return null
  }

  localDateTimeToDate(lokaali: LocalDateTime): Date | null {
    if (lokaali) {
      return new Date(lokaali.year, lokaali.month - 1, lokaali.day, lokaali.hour, lokaali.minutes, lokaali.seconds)
    }
    return null
  }
  dateToLocalMonth(pvm: Date): LocalMonth | null {
    if (pvm && this.isValidDate(pvm)) {
      return {
        year: pvm.getFullYear(),
        month: pvm.getMonth() + 1,
      } as LocalMonth
    }
    return null
  }
  dateToLocalDate(pvm: Date): LocalDate | null {
    if (pvm && this.isValidDate(pvm)) {
      return {
        year: pvm.getFullYear(),
        month: pvm.getMonth() + 1,
        day: pvm.getDate()
      } as LocalDate
    }
    return null
  }

  dateToLocalDateTime(pvm: Date): LocalDateTime | null {
    if (pvm && this.isValidDate(pvm)) {
      return {
        year: pvm.getFullYear(),
        month: pvm.getMonth() + 1,
        day: pvm.getDate(),
        hour: pvm.getHours(),
        minutes: pvm.getMinutes(),
        seconds: pvm.getSeconds()
      } as LocalDateTime
    }
    return null
  }

  dateToZonedDateTime(pvm: Date): ZonedDateTime | null {
    if (pvm && this.isValidDate(pvm)) {
      const date = this.dateToLocalDateTime(pvm) as ZonedDateTime
      date.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      return date
    }
    return null
  }

  /**
   * Output in format yymmdd
   * @param pvm
   */
  dateToNumber(pvm: Date): NumberDate {
    if (pvm && this.isValidDate(pvm)) {
      if (pvm.getFullYear() < 2000) {
        // throw new Error('Tried to convert a Date with year < 2000 to NumberDate.')
        return null
      }
      if (pvm.getFullYear() > 2099) {
        // throw new Error('Tried to convert a Date with year > 2099 to NumberDate.')
        return null
      }
      return this.localDateToNumber(this.dateToLocalDate(pvm))
    }
    return null
  }
  /**
   * Output in format yymmddhhxxss where xx is minute
   * @param date
   */
  localDateTimeToNumberTimestamp(date: LocalDateTime): NumberTimestamp | null {
    if (!date) {
      return null
    }
    if (date.year < 2000) {
      return null
      // throw new Error('Tried to convert a LocalDateTime with year < 2000 to NumberTimestamp.')
    }
    if (date.year > 2099) {
      return null
      // throw new Error('Tried to convert a LocalDateTime with year > 2099 to NumberTimestamp.')
    }
    return Math.round((((((date.year - 2000) * 100 + date.month) * 100 + date.day) * 100 + date.hour) * 100 + date.minutes) * 100 + date.seconds)
  }
  /**
   * Output in format yymmddhhxxss where xx is minute
   * @param date
   */
  dateToNumberTimestamp(date: Date): NumberTimestamp | null {
    if (!date) {
      return null
    }
    if (date.getFullYear() < 2000) {
      return null
      // throw new Error('Tried to convert a Date with year < 2000 to NumberTimestamp.')
    }
    if (date.getFullYear() > 2099) {
      return null
      // throw new Error('Tried to convert a Date with year > 2099 to NumberTimestamp.')
    }
    return this.localDateTimeToNumberTimestamp(this.dateToLocalDateTime(date))
  }
  /**
   * Input in format yymmddhhxxss where xx is minute
   * @param num
   */
  numberTimestampToDate(num: NumberTimestamp): Date | null {
    return this.localDateTimeToDate(this.numberTimestampToLocalDateTime(num))
  }
  /**
   * Input in format yymmddhhxxss where xx is minute
   * @param num
   */
  numberTimestampToLocalDateTime(num: NumberTimestamp): LocalDateTime | null {
    if (num) {
      const str = num + ''
      if (str.length !== 12) { throw new Error('Tried to convert erroneus local date number to Date: ' + str) }
      const year = '20' + str.substr(0, 2)
      const mm = str.substr(2, 2)
      const dd = str.substr(4, 2)
      const hh = str.substr(6, 2)
      const mi = str.substr(8, 2)
      const ss = str.substr(10, 2)
      return {
        day: Number(dd),
        month: Number(mm),
        year: Number(year),
        hour: Number(hh),
        minutes: Number(mi),
        seconds: Number(ss)
      }
    }
    return null
  }
  /**
   * Input in format yymmdd
   * @param pvm
   */
  numberToDate(num: NumberDate): Date | null {
    if (!num) { return null }
    return this.localDateToDate(this.numberToLocalDate(num))
  }
  /**
   * Output in format yymm
   * @param kuukausi
   */
  localMonthToNumber(kuukausi: LocalMonth): NumberMonth | null {
    if (!kuukausi) {
      return null
    }
    if (kuukausi.year < 2000) {
      return null
      // throw new Error('Tried to convert a LocalMonth with year < 2000 to NumberMonth.')
    }
    if (kuukausi.year > 2099) {
      return null
      // throw new Error('Tried to convert a LocalMonth with year > 2099 to NumberMonth.')
    }
    return Math.floor((kuukausi.year - 2000) * 100 + kuukausi.month)
  }
  /**
   * Input in format yymmdd
   * Output in format yymm
   */
  numberDateToNumberMonth(num: NumberDate): NumberMonth {
    if (!num) { return null }
    // eslint-disable-next-line no-bitwise
    if (num % 1 !== 0 || (Math.log(num) * Math.LOG10E + 1 | 0) !== 6) { throw new Error('Tried to convert erroneus local date number to number month: ' + num) }
    return Math.floor(num / 100)
  }
  /**
   * Input in format yymm
   * @param num
   */
  numberToLocalMonth(num: NumberMonth): LocalMonth | null {
    if (!num) { return null }
    // eslint-disable-next-line no-bitwise
    if (num % 1 !== 0 || (Math.log(num) * Math.LOG10E + 1 | 0) !== 4) { throw new Error('Tried to convert erroneus local month number to LocalMonth: ' + num) }
    return {
      year: 2000 + Math.floor(num / 100),
      month: Math.floor(num % 100)
    } as LocalMonth
  }

  /**
   * Check if the year / month is in supported range to be represented as number yymm.
   * The allowed range is 2010/01 - 2099/12.
   * @param num
   */
  isLocalMonthInCorrecRangeToBeRepresentedAsNumber(month: LocalMonth): boolean {
    if (2009 < month.year && month.year < 2100) {
      return true
    }
    return false
  }

  isNumberDateValid(num: NumberDate): boolean {
    if (num) {
      // eslint-disable-next-line no-bitwise
      if (num % 1 !== 0 || (Math.log(num) * Math.LOG10E + 1 | 0) !== 6) { return false }
    }
    return true
  }

  /**
   * Input in format yymmdd
   * @param num
   */
  numberToLocalDate(num: NumberDate): LocalDate | null {
    if (num) {
      if (!this.isNumberDateValid(num)) { throw new Error('Tried to convert erroneus local date number to LocalDate: ' + num) }
      return {
        day: Math.floor(num % 100),
        month: Math.floor(num % 10000 / 100),
        year: 2000 + Math.floor(num / 10000)
      }
    }
    return null
  }

  /**
   * palauttaa päivän muodossa vvkkpp, jossa v = vuosi, k = kuukausi ja p on päivä.
   * Esim. 8.11.2018 palauttaa 181108. Jos annettu paiva on undefined tai null, falsey siis,
   * palautetaan null
   * @param localDate
   */
  localDateToNumber(localDate: LocalDate): NumberDate | null {
    if (!localDate) {
      return null
    }
    return this.datePartsToNumber(localDate.year, localDate.month, localDate.day)
  }

  datePartsToNumber(year: number, month: number, day: number): NumberDate | null {
    if (year < 2000) {
      return null
      // throw new Error('Tried to convert a LocalDate with year < 2000 to NumberDate.')
    }
    if (year > 2099) {
      return null
      // throw new Error('Tried to convert a LocalDate with year > 2099 to NumberDate.')
    }
    return Math.floor(((year - 2000) * 100 + month) * 100 + day)
  }

  paiviaValissaPaikallinen(myohempi: LocalDate, aikaisempi: LocalDate): number {
    const m = this.localDateToDate(myohempi)
    const a = this.localDateToDate(aikaisempi)
    return this.paiviaValissa(m, a)
  }

  minLocalDate(...dates: LocalDate[]): LocalDate {
    if (!dates || dates.length < 1) { return null }
    if (dates.length === 1) { return dates[0] }
    let minDate: LocalDate = null
    for (const suspect of dates) {
      if (suspect && (minDate === null || this.compareLocalDates(suspect, '<', minDate))) {
        minDate = suspect
      }
    }
    return minDate
  }

  maxLocalDate(...dates: LocalDate[]): LocalDate {
    if (!dates || dates.length < 1) { return null }
    if (dates.length === 1) { return dates[0] }
    let minDate: LocalDate = null
    for (const suspect of dates) {
      if (suspect && (minDate === null || this.compareLocalDates(suspect, '>', minDate))) {
        minDate = suspect
      }
    }
    return minDate
  }

  paiviaValissaTimestamp(myohempi: Timestamp, aikaisempi: Timestamp): number {
    const aikaisempiPvm = aikaisempi.toDate()
    const myohempiPvm = myohempi.toDate()
    return differenceInCalendarDays(myohempiPvm, aikaisempiPvm)
  }

  paiviaValissa(myohempi: Date, aikaisempi: Date): number {
    return differenceInCalendarDays(myohempi, aikaisempi)
  }
  paiviaKuukaudessa(pvm: Date): number {
    return getDaysInMonth(pvm)
  }
  paiviaKuukaudessaPaikallinen(paikallinen: LocalDate): number {
    const pvm = this.localDateToDate(paikallinen)
    if (pvm) {
      return getDaysInMonth(pvm)
    }
    return null
  }
  paiviaKuukaudessaPaikallinenKuukausi(paikallinen: LocalMonth): number {
    return this.paiviaKuukaudessaPaikallinen({ year: paikallinen.year, month: paikallinen.month, day: 15 })
  }

  kuukausiaValissa(myohempi: Date, aikaisempi: Date): number {
    return differenceInCalendarMonths(myohempi, aikaisempi)
  }

  kuukausiaValissaPaikallinen(myohempi: LocalDate, aikaisempi: LocalDate): number {
    const m = this.localDateToDate(myohempi)
    const a = this.localDateToDate(aikaisempi)
    return differenceInCalendarMonths(m, a)
  }
  kuukausiaValissaLocalMonth(myohempi: LocalMonth, aikaisempi: LocalMonth): number {
    const m = new Date(myohempi.year, myohempi.month - 1, 1)
    const a = new Date(aikaisempi.year, aikaisempi.month - 1, 1)
    return differenceInCalendarMonths(m, a)
  }

  kvartaalejaValissa(myohempi: Date, aikaisempi: Date): number {
    return differenceInQuarters(myohempi, aikaisempi)
  }

  kvartaalejaValissaPaikallinen(myohempi: LocalDate, aikaisempi: LocalDate): number {
    const m = this.localDateToDate(myohempi)
    const a = this.localDateToDate(aikaisempi)
    return differenceInQuarters(m, a)
  }

  paivanAlku(paiva: Date): Date {
    return startOfDay(paiva)
  }
  paivanLoppu(paiva: Date): Date {
    return endOfDay(paiva)
  }
  kuukaudenEnsimmainen(paiva: Date): Date {
    return this.paivanAlku(startOfMonth(paiva))
  }
  kuukaudenEnsimmainenPaikallinen(paiva: LocalDate): LocalDate {
    return { year: paiva.year, month: paiva.month, day: 1 }
  }
  kuukaudenEnsimmainenNumber(paiva: NumberDate): NumberDate {
    return Math.floor(paiva / 100) * 100 + 1
  }
  kuukaudenViimeinen(paiva: Date): Date {
    return this.paivanLoppu(endOfMonth(paiva))
  }
  kuukaudenViimeinenPaikallinen(paiva: LocalDate): LocalDate {
    return this.dateToLocalDate(endOfMonth(this.localDateToDate(paiva)))
  }
  kuukaudenViimeinenNumber(paiva: NumberDate): NumberDate {
    return this.dateToNumber(endOfMonth(this.numberToDate(paiva)))
  }
  kvartaalinEnsimmainen(paiva: Date): Date {
    return startOfQuarter(paiva)
  }
  kvartaalinEnsimmainenPaikallinen(paiva: LocalDate): LocalDate {
    return this.dateToLocalDate(startOfQuarter(this.localDateToDate(paiva)))
  }
  kvartaalinViimeinen(paiva: Date): Date {
    return endOfQuarter(paiva)
  }
  kvartaalinViimeinenPaikallinen(paiva: LocalDate): LocalDate {
    return this.dateToLocalDate(endOfQuarter(this.localDateToDate(paiva)))
  }

  lisaaMinuutteja(paiva: Date, lisattavaMaara: number): Date {
    return addMinutes(paiva, lisattavaMaara)
  }

  lisaaPaivia(paiva: Date, lisattavaMaara: number): Date {
    return addDays(paiva, lisattavaMaara)
  }

  lisaaPaiviaPaikallinen(paiva: LocalDate, lisattavaMaara: number): LocalDate {
    const asDate = this.localDateToDate(paiva)
    const lisatty = addDays(asDate, lisattavaMaara)
    return this.dateToLocalDate(lisatty)
  }

  lisaaPaiviaNumber(paiva: NumberDate, lisattavaMaara: number): NumberDate {
    const asDate = this.numberToDate(paiva)
    const lisatty = this.lisaaPaivia(asDate, lisattavaMaara)
    return this.dateToNumber(lisatty)
  }

  lisaaKuukausia(paiva: Date, lisattavaMaara: number): Date {
    return addMonths(paiva, lisattavaMaara)
  }

  lisaaKuukausiaPaikallinen(paiva: LocalDate, lisattavaMaara: number): LocalDate {
    const asDate = this.localDateToDate(paiva)
    const lisatty = addMonths(asDate, lisattavaMaara)
    return this.dateToLocalDate(lisatty)
  }
  lisaaKuukausiaLocalMonth(kuukausi: LocalMonth, lisattavaMaara: number): LocalMonth {
    const asDate = new Date(kuukausi.year, kuukausi.month - 1, 15)
    const lisatty = this.dateToLocalDate(addMonths(asDate, lisattavaMaara))
    return {
      year: lisatty.year,
      month: lisatty.month
    } as LocalMonth
  }
  lisaaKuukausiaKuukaudenNumero(kuukausiNum: NumberMonth, lisattavaMaara: number): NumberMonth {
    const localMonth = this.numberToLocalMonth(kuukausiNum)
    const lisatty = this.lisaaKuukausiaLocalMonth(localMonth, lisattavaMaara)
    return this.localMonthToNumber(lisatty)
  }

  lisaaKuukausiaNumero(paiva: NumberDate, lisattavaMaara: number): NumberDate {
    const asDate = this.numberToDate(paiva)
    const lisatty = addMonths(asDate, lisattavaMaara)
    return this.dateToNumber(lisatty)
  }

  annaVuodenPaiva(paiva: Date) {
    return getDayOfYear(paiva)
  }

  minuuttejaValissa(aikaisempi: Date, myohempi: Date): number {
    return differenceInMinutes(myohempi, aikaisempi)
  }
  minuuttejaValissaPaikallinen(aikaisempi: LocalDateTime, myohempi: LocalDateTime): number {
    const aikaisempiDate = new Date(aikaisempi.year, aikaisempi.month - 1, aikaisempi.day, aikaisempi.hour, aikaisempi.minutes)
    const myohempiDate = new Date(myohempi.year, myohempi.month - 1, myohempi.day, myohempi.hour, myohempi.minutes)
    return this.minuuttejaValissa(aikaisempiDate, myohempiDate)
  }

  /**
   * palauttaa päivän muodossa vvvvkkpp, jossa v = vuosi, k = kuukausi ja p on päivä.
   * Esim. 8.11.2018 palauttaa 20181108. Jos annettu paiva on undefined tai null, falsey siis,
   * palautetaan tyhjä merkkijono
   * @param paiva
   */
  muotoileTallennusPaivamaara(paiva: LocalDate): string {
    if (paiva) {
      const mm = this.numeroMuotoilluksi(paiva.month)
      const dd = this.numeroMuotoilluksi(paiva.day)
      return paiva.year + '' + mm + '' + dd
    }
    return ''
  }

  muotoileNumberPaivaKapea(pvm: number, kieli: TuettuKieli): string | null {
    if (!kieli || !pvm) {
      return ''
    }
    const str = pvm + ''
    if (str.length !== 6) { throw new Error('Tried to convert erroneus local month day to LocalDate: ' + str) }
    const year = str.substr(0, 2)
    const mm = str.substr(2, 2)
    const dd = str.substr(4, 2)
    if (kieli === 'fi') {
      return dd + '.' + mm + '.' + year
    }
    // if (kieli === 'sv') {
    //   return year + '-' + mm + '-' + dd
    // }
    if (kieli === 'en') {
      return dd + '/' + mm + '/' + year
    }
    return 'Ei päivämäärämuotoilutukea ' + kieli
  }

  muotoileNumberPaiva(pvm: number, kieli: TuettuKieli): string | null {
    if (!kieli || !pvm) {
      return ''
    }
    const str = pvm + ''
    if (str.length !== 6) { throw new Error('Tried to convert erroneus local day to LocalDate: ' + str) }
    const year = '20' + str.substring(0, 2)
    const mm = str.substring(2, 4)
    const dd = str.substring(4, 6)
    if (kieli === 'fi') {
      return dd + '.' + mm + '.' + year
    }
    // if (kieli === 'sv') {
    //   return year + '-' + mm + '-' + dd
    // }
    if (kieli === 'en') {
      return dd + '/' + mm + '/' + year
    }
    return 'Ei päivämäärämuotoilutukea ' + kieli
  }

  muotoilePaikallinenPaiva(paiva: LocalDate, kieli: TuettuKieli): string {
    if (!kieli || !paiva) {
      return ''
    }
    const mm = this.numeroMuotoilluksi(paiva.month)
    const dd = this.numeroMuotoilluksi(paiva.day)
    if (kieli === 'fi') {
      return dd + '.' + mm + '.' + paiva.year
    }
    // if (kieli === 'sv') {
    //   return paiva.year + '-' + mm + '-' + dd
    // }
    if (kieli === 'en') {
      return dd + '/' + mm + '/' + paiva.year
    }
    return 'Ei päivämäärämuotoilutukea ' + kieli
  }

  muotoilePaiva(paiva: Timestamp, kieli: TuettuKieli): string {
    if (!paiva) {
      return ''
    }
    const d = paiva.toDate()
    return this.muotoilePaivaDate(d, kieli)
  }

  /**
   * palauttaa päivän Verohallinnon tietuekuvauksessa vaaditussa muodossa PPKKVVVVHHMMSS.
   * Esim. 8.11.2018 09:08:07 palauttaa 08112018090807. Jos annettu paiva on undefined tai null, falsey siis,
   * palautetaan tyhjä merkkijono
   * @param paiva
   */
  muotoileVeroFormaattiPaivaJaAikaDate(paiva: Date | null): string {
    if (!paiva) {
      return ''
    }
    return format(paiva, 'ddMMyyyyHHmmss')
  }

  /**
   * palauttaa päivämäärävälin Verohallinnon tietuekuvauksessa vaaditussa muodossa PPKKVVVV-PPKKVVVV.
   * Esim. 8.11.2018-5.6.2019 palauttaa 08112018-05062019. Jos annettu paiva on undefined tai null, falsey siis,
   * palautetaan tyhjä merkkijono
   * @param paiva
   */
  muotoileVeroFormaattiPaivamaravaliDate(paiva1: Date, paiva2: Date): string {
    if (!paiva1 || !paiva2) {
      return ''
    }
    return format(paiva1, 'ddMMyyyy') + '-' + format(paiva2, 'ddMMyyyy')
  }

  /**
   * palauttaa päivän Verohallinnon tietuekuvauksessa vaaditussa muodossa HHMMSS.
   * Esim. 8.11.2018 09:08:07 palauttaa 090807. Jos annettu paiva on undefined tai null, falsey siis,
   * palautetaan tyhjä merkkijono
   * @param paiva
   */
  muotoileVeroFormaattiAikaDate(paiva: Date): string {
    if (!paiva) {
      return ''
    }
    return format(paiva, 'HHmmss')
  }

  /**
   * palauttaa päivän Verohallinnon tietuekuvauksessa vaaditussa muodossa PPKKVVVV.
   * Esim. 8.11.2018 palauttaa 08112018. Jos annettu paiva on undefined tai null, falsey siis,
   * palautetaan tyhjä merkkijono
   * @param paiva
   */
  muotoileVeroFormaattiPaivaDate(paiva: Date): string {
    return this.muotoileVeroFormaattiPaiva(this.dateToLocalDate(paiva))
  }

  /**
   * palauttaa päivän Verohallinnon tietuekuvauksessa vaaditussa muodossa PPKKVVVV.
   * Esim. 8.11.2018 palauttaa 08112018. Jos annettu paiva on undefined tai null, falsey siis,
   * palautetaan tyhjä merkkijono
   * @param paiva
   */
  muotoileVeroFormaattiPaiva(paiva: LocalDate): string {
    if (!paiva) {
      return ''
    }
    return format(this.localDateToDate(paiva), 'ddMMyyyy')
  }

  /** TODO: WRITE TESTS! */
  parsiVeroFormaattiPaiva(paiva: string): LocalDate {
    if (!paiva) {
      return null
    }
    if (paiva.length !== 8) {
      throw new Error('Invalid date string ' + paiva)
    }
    return {
      day: Number(paiva.substr(0, 2)),
      month: Number(paiva.substr(2, 2)),
      year: Number(paiva.substr(4, 4))
    }
  }

  muotoilePaivaJaAikaPaikallinen(paivaJaAika: LocalDateTime, kieli: TuettuKieli): string {
    if (!kieli || !paivaJaAika) {
      return ''
    }
    return this.muotoilePaivaJaAikaDate(this.localDateTimeToDate(paivaJaAika), kieli)
  }

  muotoileYtjAikaleima(paiva: Date) {
    if (!paiva) {
      return ''
    }
    return format(paiva, 'yyyy-MM-dd HH:mm:ss')
  }

  muotoilePaivaJaAikaDate(paiva: Date, kieli: TuettuKieli): string {
    if (!kieli || !paiva) {
      return ''
    }
    if (kieli === 'fi') {
      return format(paiva, 'dd.MM.yyyy HH:mm:ss')
    }
    // if (kieli === 'sv') {
    //   return format(paiva, 'yyyy-MM-dd HH.mm.ss')
    // }
    if (kieli === 'en') {
      return format(paiva, 'dd/MM/yyyy HH:mm:ss')
    }
    return 'Ei päivämäärämuotoilutukea ' + kieli
  }
  muotoileTunnitJaMinuutit(paiva: Date): string {
    if (!paiva) {
      return ''
    }
    return format(paiva, 'HH:mm')
  }


  muotoilePaivaDate(paiva: Date, kieli: TuettuKieli): string {
    if (!kieli || !paiva) {
      return ''
    }
    if (kieli === 'fi') {
      return format(paiva, 'dd.MM.yyyy')
    }
    // if (kieli === 'sv') {
    //   return format(paiva, 'yyyy-MM-dd')
    // }
    if (kieli === 'en') {
      return format(paiva, 'dd/MM/yyyy')
    }
    return 'Ei päivämäärämuotoilutukea ' + kieli
  }

  muotoileKuukausiJaVuosi(paiva: Date, kieli: TuettuKieli): string {
    if (!kieli || !paiva) {
      return ''
    }
    if (kieli === 'fi') {
      return format(paiva, 'MM.yyyy')
    }
    // if (kieli === 'sv') {
    //   return format(paiva, 'yyyy-MM')
    // }
    if (kieli === 'en') {
      return format(paiva, 'MM/yyyy')
    }
    return 'Ei päivämäärämuotoilutukea ' + kieli
  }
  muotoileKuukausiJaVuosiPaikallinen(paiva: LocalDate, kieli: TuettuKieli): string {
    if (!kieli || !paiva) {
      return ''
    }
    const monthTwoDigits = paiva.month > 9 ? paiva.month : '0' + paiva.month
    if (kieli === 'fi') {
      return `${monthTwoDigits}.${paiva.year}`
    }
    // if (kieli === 'sv') {
    //   return `${monthTwoDigits}-${paiva.year}`
    // }
    if (kieli === 'en') {
      return `${monthTwoDigits}/${paiva.year}`
    }
    return 'Ei päivämäärämuotoilutukea ' + kieli
  }

  muotoile(paiva: Date, formaatti: string): string {
    if (!formaatti || !paiva) {
      return ''
    }
    return format(paiva, formaatti)
  }

  muotoilePaikallinen(paiva: LocalDate, formaatti: string): string {
    if (!formaatti || !paiva) {
      return ''
    }
    const asDate = this.localDateToDate(paiva)
    if (!asDate) {
      return ''
    }
    return format(asDate, formaatti)
  }

  muotoileNumber(paiva: number, formaatti: string): string {
    if (!formaatti || !paiva) {
      return ''
    }
    const asDate = this.numberToDate(paiva)
    if (!asDate) {
      return ''
    }
    return format(asDate, formaatti)
  }

  parsiKuukausi(parsittavaAny: any, kieli: TuettuKieli): Date | null {

    if (!parsittavaAny) {
      return null
    }

    if (typeof parsittavaAny !== 'string') {
      throw new Error('Kuukauden parsiminen epäonnistui kielellä "' + kieli + '" ja arvolla, joka EI OLE STRING (' + typeof parsittavaAny + '): ' + JSON.stringify(parsittavaAny) + '.')
    }

    const parsittava: string = parsittavaAny as string
    if (kieli === 'fi') {
      const str = parsittava.split('.')
      if (str.length === 2) {
        return this.tarkistaPaivamaara('1', str[0], str[1])
      }
      return new Date('thisdateisinvalidithink')
    } else if (kieli === 'en') {
      const str = parsittava.split('/')
      if (str.length === 2) {
        return this.tarkistaPaivamaara('1', str[0], str[1])
      }
      return new Date('thisdateisinvalidithink')
    }

    throw new Error('Kuukauden parsiminen epäonnistui kielellä "' + kieli + '" ja arvolla ' + JSON.stringify(parsittava) + '.')

  }

  /**
   * Yrittää parsia päivämäärän. Jos päivämäärä on virheellinen, palautetaan invalid date.
   * (Tämä vaatimus tulee angular materialista.)
   * @param parsittava parsittava string
   * @param kieli kieli, jolla parisiminen suoritetaan
   * @throws Error JOS kieltä ei tueta TAI parsittava on jotakin muuta kuin string
   */
  parsiPaivamaara(parsittavaAny: any, kieli: TuettuKieli): Date | null {

    if (!parsittavaAny) {
      return null
    }

    if (typeof parsittavaAny !== 'string') {
      throw new Error('Päivämäärän parsiminen epäonnistui kielellä "' + kieli + '" ja arvolla, joka EI OLE STRING (' + typeof parsittavaAny + '): ' + JSON.stringify(parsittavaAny) + '.')
    }

    const parsittava: string = parsittavaAny as string
    if (kieli === 'fi') {
      const str = parsittava.split('.')
      if (str.length === 3) {
        return this.tarkistaPaivamaara(str[0], str[1], str[2])
      }
      return new Date('thisdateisinvalidithink')
    } else if (kieli === 'en') {
      const str = parsittava.split('/')
      if (str.length === 3) {
        return this.tarkistaPaivamaara(str[0], str[1], str[2])
      }
      return new Date('thisdateisinvalidithink')
    }

    throw new Error('Päivämäärän parsiminen epäonnistui kielellä "' + kieli + '" ja arvolla ' + JSON.stringify(parsittava) + '.')

  }

  /**
   * Yrittää parsia päivämäärän. Jos päivämäärä on virheellinen, palautetaan invalid date.
   * (Tämä vaatimus tulee angular materialista.)
   * @param parsittava parsittava string
   * @param kieli kieli, jolla parisiminen suoritetaan
   * @throws Error JOS kieltä ei tueta TAI parsittava on jotakin muuta kuin string
   */
  private tarkistaPaivamaara(pp: string, kk: string, vvvv: string): Date {

    const paiva = Number(pp)
    const kuukausi = Number(kk)
    const vuosi = Number(vvvv)

    if (isNaN(paiva) || isNaN(kuukausi) || isNaN(vuosi)) {
      return new Date('thisdateisinvalidithink')
    }

    if (this.onkoPaivamaaraValidi(paiva, kuukausi, vuosi)) {
      return new Date(vuosi, kuukausi - 1, paiva, 12)
    }

    return new Date('thisdateisinvalidithink')

  }

  parsiIso8601(dateString: string): Date | null {
    if (!dateString || !dateString.trim()) {
      return null
    }
    return parseISO(dateString)
  }

  /** Parsi ISO päivämäärä 2018-02-12T7:19:10.308Z */
  parsiPaivaIsoFormaatista(dateString: string): Date | null {

    if (!dateString) {
      return null
    }

    if (Object.prototype.toString.call(dateString) === '[object String]') {
      const trimmed = dateString.trim()
      if (trimmed.length < 1) {
        return null
      }

      // Jaa päivämäärä ja aika osio
      if ((trimmed.indexOf(' ') > -1 || trimmed.indexOf('T')) && (trimmed.indexOf('Z') > -1 || trimmed.indexOf('+') > -1 || trimmed.indexOf('-'))) {
        const jaettuKaikki = trimmed.split(/[ T]/)
        const jaettuPvm = jaettuKaikki[0].split('-')
        const aikaJaTimezone = jaettuKaikki[1]
        const jaettuAikaJaTimezone = aikaJaTimezone.indexOf('Z') > -1 ? aikaJaTimezone.split('Z') : aikaJaTimezone.split(/[\-+]/)
        const jaettuAika = jaettuAikaJaTimezone[0].split(':')

        const vuosi = Number(jaettuPvm[0])
        const kuukausi = Number(jaettuPvm[1]) - 1
        const paiva = Number(jaettuPvm[2])

        const tunti = Number(jaettuAika[0])
        const minuutti = Number(jaettuAika[1])

        const sekunnit = jaettuAika[2] || '0'
        const sekuntiString = sekunnit.indexOf('.') > -1 ? sekunnit.split('.')[0] : sekunnit
        const millisekunnitString = sekunnit.indexOf('.') > -1 ? sekunnit.split('.')[1] + '000' : '000'
        const millisekuntiString = millisekunnitString.length > 3 ? millisekunnitString.substr(0, 3) : millisekunnitString

        const sekunti = Number(sekuntiString)
        const millisekunti = Number(millisekuntiString)

        const timezone = jaettuAikaJaTimezone[1]
        const jaettuTimezone = timezone.split(':')
        const timezoneTuntiero = jaettuTimezone?.length > 1 ? Number(jaettuTimezone[0]) : 0

        const dateUtc = Date.UTC(vuosi, kuukausi, paiva, tunti, minuutti, sekunti, millisekunti)

        return new Date(dateUtc + timezoneTuntiero * 60 * 1000)
      }
      return new Date(trimmed)
    }

    return new Date(dateString)
  }

  private numeroMuotoilluksi(numero: number): string {
    return numero < 10 ? '0' + numero : '' + numero
  }

  public onkoLocalDateValidi(date: LocalDate): boolean {
    if (date) {
      return this.onkoPaivamaaraValidi(date.day, date.month, date.year)
    }
    return false
  }

  public onkoKuunViimeinen(date: Date): boolean {
    if (!date || isNaN(date.getTime())) { return false }
    const test = new Date(date.getTime())
    test.setDate(test.getDate() + 1)
    return test.getDate() === 1
  }

  public onkoPaivamaaraValidi(paiva: number, kuukausi: number, vuosi: number): boolean {

    // Firestoren vuosi on maksimissaan 9999, minimissään 1.
    // Haluamme kuitenkin aina nelinumeroisen vuosiluvun, eikä
    // järjestelmässä ole mitään ennen vuotta 1900.
    if (vuosi > 9999 || vuosi < 1900) {
      return false
    }

    // Helmikuussa karkausvuosimahis
    if (kuukausi === 2) {
      if (this.onkoKarkausvuosi(vuosi)) {
        return paiva > 0 && paiva < 30
      } else {
        return paiva > 0 && paiva < 29
      }
    }

    // https://www.timeanddate.com/calendar/months/
    if (
      kuukausi === 4 ||
      kuukausi === 6 ||
      kuukausi === 9 ||
      kuukausi === 11
    ) {
      return paiva > 0 && paiva < 31
    }

    if (
      kuukausi === 1 ||
      kuukausi === 3 ||
      kuukausi === 5 ||
      kuukausi === 7 ||
      kuukausi === 8 ||
      kuukausi === 10 ||
      kuukausi === 12
    ) {
      return paiva > 0 && paiva < 32
    }

    return false

  }

  // Lainattu date-fns:iltä: https://github.com/date-fns/date-fns/blob/v1/src/is_leap_year/index.js
  public onkoKarkausvuosi(year: number): boolean {
    return year % 400 === 0 || year % 4 === 0 && year % 100 !== 0
  }

  // public onkoLocalDateYhtaSuuriTaiSuurempiKuinToinen(tarkistettava: LocalDate, onkoPienempi: LocalDate): boolean {

  //   if (tarkistettava.year > onkoPienempi.year) {
  //     return true
  //   } else if (tarkistettava.year === onkoPienempi.year) {
  //     if (tarkistettava.month > onkoPienempi.month) {
  //       return true
  //     } else if (tarkistettava.month === onkoPienempi.month) {
  //       return tarkistettava.day >= onkoPienempi.day
  //     }
  //   }

  //   return false

  //   // const t = this.localDateToDate(tarkistettava)
  //   // const a = this.localDateToDate(onkoPienempi)
  //   // return differenceInCalendarDays(t, a) >= 0
  // }

  // public onkoLocalDateYhtaSuuriTaiPienempiKuinToinen(tarkistettava: LocalDate, onkoSuurempi: LocalDate): boolean {
  //   return this.onkoLocalDateYhtaSuuriTaiSuurempiKuinToinen(onkoSuurempi, tarkistettava)
  // }

  public compareLocalDateTimes(localDate1: LocalDateTime, operator: ComparisonOperators, localDate2: LocalDateTime): boolean {
    if (!localDate1) {
      throw new Error('1st LocalDateTime missing')
    }
    if (!localDate2) {
      throw new Error('2nd LocalDateTime missing')
    }
    const date1 = this.localDateTimeToDate(localDate1)
    const date2 = this.localDateTimeToDate(localDate2)
    return this.compareDates(date1, operator, date2)
  }
  public compareLocalMonths(localMonth1: LocalMonth, operator: ComparisonOperators, localMonth2: LocalMonth): boolean {
    if (!localMonth1) {
      throw new Error('1st LocalMonth missing')
    }
    if (!localMonth2) {
      throw new Error('2nd LocalMonth missing')
    }
    switch (operator) {
      case '<':
        return localMonth1.year < localMonth2.year || (localMonth1.year === localMonth2.year && localMonth1.month < localMonth2.month)
      case '>':
        return localMonth1.year > localMonth2.year || (localMonth1.year === localMonth2.year && localMonth1.month > localMonth2.month)
      case '==':
        return localMonth1.year === localMonth2.year && localMonth1.month === localMonth2.month
      case '!=':
        return localMonth1.year !== localMonth2.year || localMonth1.month !== localMonth2.month
      case '<=':
        return localMonth1.year < localMonth2.year || (localMonth1.year === localMonth2.year && localMonth1.month <= localMonth2.month)
      case '>=':
        return localMonth1.year > localMonth2.year || (localMonth1.year === localMonth2.year && localMonth1.month >= localMonth2.month)
      default:
        throw new Error('Unknown operator: ' + operator)
    }
  }
  public compareLocalDates(localDate1: LocalDate, operator: ComparisonOperators, localDate2: LocalDate): boolean {
    if (!localDate1) {
      throw new Error('1st LocalDate missing')
    }
    if (!localDate2) {
      throw new Error('2nd LocalDate missing')
    }
    switch (operator) {
      case '<':
        return localDate1.year < localDate2.year || (localDate1.year === localDate2.year && localDate1.month < localDate2.month) || (localDate1.year === localDate2.year && localDate1.month === localDate2.month && localDate1.day < localDate2.day)
      case '>':
        return localDate1.year > localDate2.year || (localDate1.year === localDate2.year && localDate1.month > localDate2.month) || (localDate1.year === localDate2.year && localDate1.month === localDate2.month && localDate1.day > localDate2.day)
      case '==':
        return localDate1.year === localDate2.year && localDate1.month === localDate2.month && localDate1.day === localDate2.day
      case '!=':
        return localDate1.year !== localDate2.year || localDate1.month !== localDate2.month || localDate1.day !== localDate2.day
      case '<=':
        return localDate1.year < localDate2.year || (localDate1.year === localDate2.year && localDate1.month < localDate2.month) || (localDate1.year === localDate2.year && localDate1.month === localDate2.month && localDate1.day <= localDate2.day)
      case '>=':
        return localDate1.year > localDate2.year || (localDate1.year === localDate2.year && localDate1.month > localDate2.month) || (localDate1.year === localDate2.year && localDate1.month === localDate2.month && localDate1.day >= localDate2.day)
      default:
        throw new Error('Unknown operator: ' + operator)
    }
  }
  public compareTimestamps(timestamp1: Timestamp, operator: ComparisonOperators, timestamp2: Timestamp): boolean {
    if (!timestamp1) {
      throw new Error('1st Timestamp missing')
    }
    if (!timestamp1) {
      throw new Error('2nd Timestamp missing')
    }
    const date1 = this.timestampToDate(timestamp1)
    const date2 = this.timestampToDate(timestamp2)
    return this.compareDates(date1, operator, date2)
  }
  public compareNumberDates(date1: NumberDate, operator: ComparisonOperators, date2: NumberDate): boolean {
    if (!date1) {
      throw new Error('1st NumberDate missing')
    }
    if (!date2) {
      throw new Error('2nd NumberDate missing')
    }
    switch (operator) {
      case '<':
        return date1 < date2
      case '>':
        return date1 > date2
      case '==':
        return date1 === date2
      case '!=':
        return date1 !== date2
      case '<=':
        return date1 <= date2
      case '>=':
        return date1 >= date2
      default:
        throw new Error('Unknown operator: ' + operator)
    }
  }
  /**
  * NB! Not for public use, not optimized for timezone diffs
  */
  private compareDates(date1: Date, operator: ComparisonOperators, date2: Date): boolean {
    switch (operator) {
      case '<':
        return compareDesc(date1, date2) === 1 // Returns 1 if date1 is before date2
      case '>':
        return compareAsc(date1, date2) === 1 // Returns 1 if date1 is after dat2
      case '==':
        return isEqual(date1, date2)
      case '!=':
        return !isEqual(date1, date2)
      case '<=':
        return [0, 1].includes(compareDesc(date1, date2)) // Returns 1 if date1 is before date2, 0 if equal
      case '>=':
        return [0, 1].includes(compareAsc(date1, date2)) // Returns 1 if date1 is after date2, 0 if equal
      default:
        throw new Error('Unknown operator: ' + operator)
    }
  }
  public onkoLocalDateTimeKahdenValissa(tarkistettava: LocalDateTime, alku: LocalDateTime, loppu: LocalDateTime) {
    return this.compareLocalDateTimes(tarkistettava, '>=', alku) && this.compareLocalDateTimes(tarkistettava, '<=', loppu)
  }
  public onkoLocalDateKahdenValissa(tarkistettava: LocalDate, alku: LocalDate, loppu: LocalDate) {
    return this.compareLocalDates(tarkistettava, '>=', alku) && this.compareLocalDates(tarkistettava, '<=', loppu)
  }
  public onkoLocalMonthKahdenValissa(tarkistettava: LocalMonth, alku: LocalMonth, loppu: LocalMonth) {
    return this.compareLocalMonths(tarkistettava, '>=', alku) && this.compareLocalMonths(tarkistettava, '<=', loppu)
  }
  /**
   * @param tarkistettava Must be in format yymmdd
   * @param alku Must be in format yymmdd
   * @param loppu Must be in format yymmdd
   */
  public onkoNumberKahdenValissa(tarkistettava: NumberDate, alku: NumberDate, loppu: NumberDate): boolean {
    return tarkistettava >= alku && tarkistettava <= loppu
  }

  public onkoLocalMonthEnnen(ennen: LocalMonth, jalkeen: LocalMonth): boolean {
    if (ennen.year < jalkeen.year) {
      return true
    } else if (ennen.year === jalkeen.year) {
      return ennen.month < jalkeen.month
    }
    return false
  }

  // January - 31 days
  // February - 28 days in a common year and 29 days in leap years
  // March - 31 days
  // April - 30 days
  // May - 31 days
  // June - 30 days
  // July - 31 days
  // August - 31 days
  // September - 30 days
  // October - 31 days
  // November - 30 days
  // December - 31 days

  public annaPaivienMaaraKuukaudessa(kuukausi: LocalMonth): number {

    if (kuukausi.month === 1) {
      return 31
    } else if (kuukausi.month === 2) {
      if (this.onkoKarkausvuosi(kuukausi.year)) {
        return 29
      } else {
        return 28
      }
    } else if (kuukausi.month === 3) {
      return 31
    } else if (kuukausi.month === 4) {
      return 30
    } else if (kuukausi.month === 5) {
      return 31
    } else if (kuukausi.month === 6) {
      return 30
    } else if (kuukausi.month === 7) {
      return 31
    } else if (kuukausi.month === 8) {
      return 31
    } else if (kuukausi.month === 9) {
      return 30
    } else if (kuukausi.month === 10) {
      return 31
    } else if (kuukausi.month === 11) {
      return 30
    } else if (kuukausi.month === 12) {
      return 31
    } else {
      throw new Error('Unknown month: ' + JSON.stringify(kuukausi))
    }

  }
  /** Kuukausi according to JS date (tammikuu = 0) */
  public annaKuukaudenNimi(kuukausi: number, kieli: TuettuKieli, pituus?: 'long' | 'short' | 'narrow'): string {
    if (kuukausi === undefined && kuukausi === null) {
      return ''
    }
    if (kieli === 'en') {
      return this.KUUKAUSIEN_NIMET.en[pituus || 'long'][kuukausi]
    }
    return this.KUUKAUSIEN_NIMET.fi[pituus || 'long'][kuukausi]
  }

  public annaKuukaudenNimiPaikallinen(kuukausiPaikallinen: LocalMonth | LocalDate, kieli: TuettuKieli, pituus?: 'long' | 'short' | 'narrow'): string {
    return this.annaKuukaudenNimi(kuukausiPaikallinen.month - 1, kieli, pituus)
  }

  public annaPaivanNimi(paiva: Date, kieli: TuettuKieli, pituus?: 'long' | 'short' | 'narrow'): string {
    if (!paiva) {
      return ''
    }
    if (kieli === 'en') {
      return this.PAIVIEN_NIMET.en[pituus || 'long'][paiva.getDay()]
    }
    return this.PAIVIEN_NIMET.fi[pituus || 'long'][paiva.getDay()]
  }

  public annaPaivanNimiPaikallinen(paivaPaikallinen: LocalDate, kieli: TuettuKieli, pituus?: 'long' | 'short' | 'narrow'): string {
    return this.annaPaivanNimi(this.localDateToDate(paivaPaikallinen), kieli, pituus)
  }

}
