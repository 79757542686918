import { ErrorResponse, NumberDate, TuettuKieli } from '../../_shared-core/model/common'

// eslint-disable-next-line no-shadow
export enum RaporttiType {
  TULOS = '1',
  TASE = '2',
  PAAKIRJA = '3',
  TULOS_VIRALLINEN = '4',
  TASE_VIRALLINEN = '5',
  VEROILMOITUS = '6',
  SELVITETTAVAT_OSTOT = '7',
  SELVITETTAVAT_MYYNNIT = '8',
  PAIVAKIRJA = '9',
  ALV_ILMOITUS = '10',
  TASE_ERITTELY = '11',
  TASE_JA_TULOS_VIRALLINEN = '12',
  OSS = '13',
  TILIOTE = '14',
  TILINPAATOS = '15',
  YHTIOKOKOUKSEN_POYTAKIRJA = '16',
  PRH_TILINPAATOSILMOITUS = '17',
  LUETTELO_KIRJANPIDOISTA_JA_AINEISTOISTA = '18',
  TILIKARTTA = '19',
  HALLITUKSEN_POYTAKIRJA = '20',
  TULOS_BRUTTO = '21',
  RESKONTRA = '22',
  TASE_JA_TULOS = '23',
  ALV_LASKELMA = '24'
}

export interface LemonaidRaporttiPaakirjaData {
  r: LemonaidRaporttiPaakirjaAccountRow[]
}

export interface LemonaidRaporttiPaakirjaAccountRow {
  /** grand Parent */
  p: string

  /** account number */
  a: string

  /** Opening number */
  o: number

  /** Debet value */
  b: number

  /** Kredit value */
  k: number

  /** Saldo */
  s: number

  /** Rows */
  d?: LemonaidRaporttiDetailRow[]

  /** THESE BELOW ARE NEVER POPULATED BY BACKEND!! */

  /** Displayed name, like 3000 Myynti 24% */
  n?: string
}

export interface LemonaidRaporttiDetailRow {

  /** Kirjauksen Avain */
  ka?: string

  /** Debet */
  d?: number
  /** Kredit */
  k?: number
  /** Saldo */
  s: number

  /** kirjausNumero */
  n: string
  /** Pvm */
  p: number
  /** Maksutapa */
  m: string
  /** alv, Vat */
  v?: string
  /** Brojekti */
  b?: string
  /** maksunsaaja, payment Receiver */
  r?: string

  /** Accountants comment */
  a?: string
  /** lemonaid Comment */
  c?: string
  /** other comments, Explanations */
  e?: string

  /** onko rivi Laajennettu (Expanded?) */
  l?: true
  /** Maksutavan nimi */
  ma?: string
}

export interface LemonaidRaporttiTaselaskelmaData {
  c: LemonaidTaselaskelmaReportColumnCount
  r: LemonaidRaporttiTaselaskelmaAccountRow[]
  c1: LemonaidRaporttiColumnInfo
  c2?: LemonaidRaporttiColumnInfo
  e?: 'a' // ANNETTU_AIKAVALI_EI_OLE_TILIKAUDELLA
}

/** How many columns are in the report? */
export type LemonaidTaselaskelmaReportColumnCount = 'c1' | 'c2'

export interface LemonaidRaporttiColumnInfo {
  /** range start date, yymmdd */
  s: NumberDate
  /** range end date, yymmdd */
  e: NumberDate
}

export interface LemonaidRaporttiTaselaskelmaAccountRow {

  /** level, depth */
  l: number

  /** account number */
  a: string

  /** Opening value for column 1 */
  o1: number

  /** Opening value for column 2 */
  o2?: number

  /** Sum value for column 1 */
  s1: number

  /** Sum value for column 2 */
  s2?: number

  /** Muutos === s2 + s1 */
  m?: number

  /** Is sum row? */
  s?: 1

  /** Details */
  d?: LemonaidRaporttiDetailRow[]

  /** THESE BELOW ARE NEVER POPULATED BY BACKEND!! */

  /** Displayed name, like 3000 Myynti 24% */
  n?: string
  /** Is the row expanded? */
  e?: 1
  /** Is the data still loading for row expansion? */
  r?: 1
  /** Detail Kredit Sum */
  dks?: number
  /** Detail Debit Sum */
  dds?: number
  /** Detail Saldo Sum */
  dss?: number

}


export interface LemonaidRaporttiTuloslaskelmaAccountRow {

  /** level, depth */
  l: number

  /** account number */
  a: string

  s1: number // Sum value for column 1
  s2?: number // Sum value for column 2
  s3?: number // Sum value for column 3
  s4?: number // Sum value for column 4

  /** Is sum row? */
  s?: 1

  /** Details */
  d?: LemonaidRaporttiDetailRow[]

  /** THESE BELOW ARE NEVER POPULATED BY BACKEND!! */

  /** Displayed name, like 3000 Myynti 24% */
  n?: string
  /** Is the row expanded? */
  e?: 1
  /** Is the data still loading for row expansion? */
  r?: 1
  /** Detail Kredit Sum */
  dks?: number
  /** Detail Debit Sum */
  dds?: number

}

/** How many columns are in the report? */
export type LemonaidTuloslaskelmaReportColumnCount = 'c1' | 'c2' | 'c3' | 'c4'

export interface LemonaidRaporttiTuloslaskelmaData {
  c: LemonaidTuloslaskelmaReportColumnCount
  r: LemonaidRaporttiTuloslaskelmaAccountRow[]
  c1: LemonaidRaporttiColumnInfo
  c2?: LemonaidRaporttiColumnInfo
  c3?: LemonaidRaporttiColumnInfo
  c4?: LemonaidRaporttiColumnInfo
  e?: 'a' // ANNETTU_AIKAVALI_EI_OLE_TILIKAUDELLA
}

export interface LemonaidKirjanpidonRaporttiRequest {

  /** Asiakas avain */
  a: string
  /** Kieli */
  k: TuettuKieli
  /** The type of report */
  w: RaporttiType.PAAKIRJA | RaporttiType.TASE | RaporttiType.TULOS | RaporttiType.TULOS_BRUTTO | RaporttiType.TASE_VIRALLINEN | RaporttiType.TULOS_VIRALLINEN

  /** Start, format yymmdd */
  s: number
  /** End, format yymmdd */
  e: number

  /** return Detail rows? */
  d?: 1
  /** return detail rows for account numbers? */
  f?: string[]
  /** return detail rows for account numbers, from times beginning? */
  ff?: string[]

}

export interface LemonaidKirjanpidonRaporttiPdfRequest extends Omit<LemonaidKirjanpidonRaporttiRequest, 'w'> {
  w: RaporttiType.PAAKIRJA | RaporttiType.TASE | RaporttiType.TULOS | RaporttiType.TULOS_BRUTTO | RaporttiType.TASE_VIRALLINEN | RaporttiType.TULOS_VIRALLINEN | RaporttiType.TASE_JA_TULOS_VIRALLINEN | RaporttiType.TASE_JA_TULOS
}

export interface LemonaidKirjanpidonRaportinPdfResponse extends ErrorResponse {
  ok?: boolean
  base64File?: string
}


export interface LemonaidKirjanpidonRaporttiResponse extends ErrorResponse {
  ok?: boolean
  data?: LemonaidRaporttiPaakirjaData | LemonaidRaporttiTaselaskelmaData | LemonaidRaporttiTuloslaskelmaData | LemonaidRaporttiBruttoTuloslaskelmaData | LemonaidRaporttiVirallinenTuloslaskelmaData | LemonaidRaporttiVirallinenTaseData
}


export interface LemonaidRaporttiVirallinenTuloslaskelmaData {
  c1Header: string
  c2Header: string
  c: 'c1' | 'c2'
  data: LemonaidRaporttiVirallinenTuloslaskelmarivi[]
  e?: 'a' //  ANNETTU_AIKAVALI_EI_OLE_TILIKAUDELLA
}

export interface LemonaidRaporttiVirallinenTuloslaskelmarivi {
  /** Nimi */
  n: string
  /** Level */
  l: number
  /** Sum 1 */
  s1: number
  /** Sum 2 */
  s2: number
  /** Is summary row */
  s?: 1
}

export interface LemonaidRaporttiVirallinenTaseData {
  c1Header: string
  c2Header: string
  c: 'c1' | 'c2'
  data: LemonaidRaporttiVirallinenTaselaskelmarivi[]
  e?: 'a' //  ANNETTU_AIKAVALI_EI_OLE_TILIKAUDELLA
}

export interface LemonaidRaporttiBruttoTuloslaskelmaData {
  c1Header: string
  c2Header: string
  c: 'c1' | 'c2'
  data: LemonaidRaporttiBruttoTuloslaskelmarivi[]
  e?: 'a' //  ANNETTU_AIKAVALI_EI_OLE_TILIKAUDELLA
}

export interface LemonaidRaporttiBruttoTuloslaskelmarivi {
  /** Nimi */
  n: string
  /** Level */
  l: number
  /** Sum 1 */
  s1: number
  /** Sum 2 */
  s2: number
  /** Is summary row */
  s?: 1
}

export interface LemonaidRaporttiVirallinenTaselaskelmarivi {
  /** Nimi */
  n: string
  /** Level */
  l: number
  /** Sum 1 */
  s1: number
  /** Sum 2 */
  s2: number
  /** Is heading row */
  h?: boolean
  /** Is summary row */
  s?: boolean
  /** Is vastaavaa/vastattavaa summary row */
  vs?: boolean
}

export enum LemonaidTaseKolumnienVirhe {
  ANNETTU_PAIVA_EI_OLE_TILIKAUDELLA = 'a'
}

export interface RaporttiVirallinenTaselaskelmaData {
  e?: LemonaidTaseKolumnienVirhe.ANNETTU_PAIVA_EI_OLE_TILIKAUDELLA
  c1Header: string
  c2Header: string
  c: 'c1' | 'c2'
  data: LemonaidRaporttiVirallinenTaselaskelmarivi[]
}



