import { BaseEntity, ErrorResponse, LocalDate, NumberDate, Timestamp, TuettuKieli } from '../../_shared-core/model/common'

export const EI_KIRJANPITAJAA_AVAIN = 'eitiedossa'
export interface ImpersonoidunKayttajanTiedot {
  avain: string
  etunimi: string
  sukunimi: string
  email: string
}

export interface ImpersonoidunAsiakkaantTiedot {
  avain: string
  nimi: string
  ytunnus: string
  asiakasId: number
}

export type KirjanpitoRaporttiValilehti = 'tase-ja-tulos' | 'viralliset' | 'paakirja' | 'bruttolaskelma' | 'oss-myynnit' | 'alv-laskelma'
export type KirjanpitoRaporttiEnsinNaytettava = 'tulos' | 'tase'
export interface KirjanpitajanAsetukset {
  kirjanpitajanAvain: string
  /**
   * Number must be in format yymm
   */
  asiakasListausAlkuKk: number
  /**
   * Number must be in format yymm
   */
  asiakasListausLoppuKk: number
  /**
   * Kirjanpidossa oletuksena avoinna oleva välilehti
   */
  kirjanpidonOletusraportti: KirjanpitoRaporttiValilehti
  /**
   * Näkymissä, joissa on samaan aikaan sekä tulos, että tase, kumpi näytetään ensin?
   */
  kirjanpidonEnsinNaytettevaRaportti: KirjanpitoRaporttiEnsinNaytettava
}

export interface Kirjanpitaja extends BaseEntity {
  etunimi: string
  sukunimi: string
  email: string
  puhelin?: string
  kuvaUrl?: string
  signatureImageUrl?: string
  lemonaidUid: string
  lemonaidAsiakasAvain: string
  impersonoituKayttaja?: ImpersonoidunKayttajanTiedot
  impersonoituAsiakas?: ImpersonoidunAsiakkaantTiedot
  /**
   * Number must be in format yymmdd
   */
  aloitti: number
  toimipisteAvain?: string
  workTimeSettings: KirjanpitajaWorkTimeChange[]

  emailEndText?: { [kieli in TuettuKieli]: string }
  lomaviesti?: { [kieli in TuettuKieli]: string }
}

export interface KirjanpitajaWorkTimeChange {
  percentage: number
  /** Date must be the first day of month! */
  validFrom: NumberDate
  changedAt: Timestamp
  changedBy: string
}

export interface KirjanpitajanNimitiedot extends Pick<Kirjanpitaja, 'avain' | 'etunimi' | 'sukunimi' | 'email' | 'puhelin' | 'toimipisteAvain' | 'signatureImageUrl' | 'emailEndText' | 'lomaviesti' | 'lemonaidUid' | 'poistettu'> {
}

export interface KirjanpitajanSarake {
  aktiivinen: boolean
  avain: string
  nimi: string
  tilat: KirjanpitajanSarakkeenTila[]
  tallentanut: string
  tallennettu: Timestamp
  jarjestys: number
}

export interface KirjanpitajanSarakkeenTila {
  avain: string
  nimi: string
  kuvaus: string
  vari: string
  oletus: boolean
}

export interface KirjanpitajanSarakkeenArvo {
  /**
   * Arvo (Value)
   */
  v: string
  /**
   * Päivitetty, muotoa yymmddhhmmss (Updated)
   */
  u: number
  /**
   * Päivittäjä
   */
  p: string
}

/**
 * kirjanpitajat/{kirjanpitajanAvain}/asiakaslistauksen-sarakkeiden-arvot/{asiakasAvain}
 */
export interface KirjanpitajanSarakkeidenArvotAsiakkaalle {
  /**
   * Asiakkaan avain
   */
  a: string
  /**
   * Sarakkeiden arvot (Values)
   */
  v: { [sarakkeenAvain: string]: KirjanpitajanSarakkeenArvo }
  /**
   * Primääri kirjanpitäjä
   */
  p: string
  /**
   * Sekundäärinen (vara)kirjanpitäjä
   */
  s?: string
}

export interface KirjanpitajanAsiakkaat extends BaseEntity {
  kirjanpitajanAsiakkaat: KirjanpitajanAsiakas[]
  kirjanpitajanAsiakkaatVara: KirjanpitajanAsiakas[]
}

export interface KirjanpitajanAsiakas {
  asiakasAvain: string
  voimassaoloAlkaa: LocalDate
  voimassaoloPaattyy: LocalDate | null
}

export interface AloitaKayttajanaKirjautuminenPyynto {
  asiakasAvain: string
  kayttajaAvain: string
}

export interface AloitaKayttajanaKirjautuminenVastaus { }

export interface LopetaKayttajanaKirjautuminenPyynto { }

export interface LopetaKayttajanaKirjautuminenVastaus { }

export interface AnnaKirjanpitajienNimitiedotPyynto { }

export interface AnnaKirjanpitajienNimitiedotVastaus {
  nimitiedot: KirjanpitajanNimitiedot[]
}

export interface KirjanpitajanKuvanUploadRequest {
  kirjanpitajanAvain: string
  fileName: string
  fileBase64: string
}
export interface KirjanpitajanKuvanUploadResponse extends ErrorResponse { }

export interface KirjanpitajanToimipiste {
  avain: string
  nimi: string
  katuosoite: string
  postinro: string
  kaupunki: string
  maa: string
  puhnro: string
}

export interface KirjanpitajanTiiminJasen {
  kirjanpitajaAvain: string
  /** Milloin aloitti tiimissä */
  alku: NumberDate
  /** Milloin lopetti tiimissä */
  loppu: NumberDate | null
}

export interface KirjanpitajanTiimi {
  avain: string
  nimi: string
  esihenkilot: KirjanpitajanTiiminJasen[]
  /** Milloin perustettiin */
  alku: NumberDate
  /** Milloin lopetettiin */
  loppu: NumberDate | null
  jasenet: KirjanpitajanTiiminJasen[]
}
